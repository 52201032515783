import { leftPadTo } from "./strings";

export function formatFloat(decimal: number, places: number) {
  if (isNaN(decimal)) return 0;
  return Number.parseFloat(`${Math.round(Number.parseFloat(`${decimal}e+${places}`))}e-${places}`);
}

export function isNumeric(str: string): boolean {
  return /^-?\d+$/.test(str);
}

export const roundToDecimalPlaces = (value: number, decimals: number) =>
  (Math.round(value * 100) / 100).toFixed(decimals);

export default [formatFloat];

export const addLeadingZeroes = (num: number, minSize: number) => leftPadTo(`${num}`, minSize, "0");

export const isEven = (num: number) => Math.round(num / 2) === num / 2;
export const isOdd = (num: number) => !isEven(num);
